import React from 'react'
import { useStoreActions } from 'easy-peasy'
import styled from 'styled-components'
import ListItem from '@lib/ui/core/ListItem'
import SvgIcon from '@lib/ui/core/SvgIcon'
import Typography from '@lib/ui/core/Typography'
import { ReactComponent as Featured } from '@assets/icons/featured-interest.svg'
import { ReactComponent as Check } from '@assets/icons/interest-check.svg'
import { ReactComponent as Uncheck } from '@assets/icons/interest-uncheck.svg'

const FeaturedDecoration = styled(({ ...props }) => (<Featured {...props} />))`
  position: absolute;
  transform: translateY(-.25rem);
`

const InterestItem = ({ interest, deleteId, add, featured }) => {
  const addInterest = useStoreActions((actions) => actions.user.addInterest)
  const delInterest = useStoreActions((actions) => actions.user.delInterest)
  const handleClick = () => (
    add ? addInterest({ id: interest.prismicId }) : delInterest({ id: deleteId })
  )

  return (
    <ListItem
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".7rem .2rem",
      }}
      onClick={handleClick}
      button
    >
      <Typography variant="subtitle1" fontSize="0.5rem">
        {interest.uid}
        {featured && <SvgIcon component={FeaturedDecoration} fontSize=".6rem" />}
      </Typography>
      <SvgIcon component={add ? Uncheck : Check} fontSize="1.25rem" />
    </ListItem>
  )
}

export default InterestItem
