import React from 'react'
import Layout from '@layouts/MainLayout'
import SEO from '@components/SEO'
import Auth from '@components/Auth'
import AccountPanel from '@components/Account'

const Account = () => (
  <Auth mode="needlessAuth">
    <Layout>
      <SEO />
      <AccountPanel />
    </Layout>
  </Auth>
)

export default Account
