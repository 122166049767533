import React from 'react'
import styled from 'styled-components'
import Box from '@lib/ui/core/Box'
import Grid from '@lib/ui/core/Grid'
import Typography from '@lib/ui/core/Typography'
import TickIcon from './TickIcon'


const Img = styled.img`
  src: ${(props) => props.imgUrl};
  alt: ${(props) => props.imgUrl};
  width: 100%;
  border-radius: .25rem;
`

const Card = ({ card, handleChoose, isCreditCardClicked }) => (
  <Box position="relative" pt={1} clone>
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
    >
      <Box width="100%" style={{ cursor: 'pointer' }} height="0px" pb="63%" overflow="hidden">
      {card.card_image.url !== undefined}
        <Img
          src={card.card_image.url}
          alt={card.card_image.alt}
          onClick={() => handleChoose(card)}
        />
      </Box>
      {isCreditCardClicked(card.prismicId) && <TickIcon />}
      <Box my=".5rem">
        <Typography align="center">{card.name}</Typography>
      </Box>
    </Grid>
  </Box>
)

const FilterByCard = ({ creditCards, isCreditCardClicked, handleChoose }) => (
  <Box width="100%" mt="-2rem" borderRadius="2rem" display="flex" flexWrap="wrap" bgcolor="grey.100" pt="4rem" px="1.5rem" pb="2rem">
    {/* padding="4rem 1.4rem 2rem 1.4rem" */}
    <Grid spacing={3} container>
      {
        creditCards.length > 0
          && creditCards.map((card) => (
            <Box key={card.prismicId} clone>
              <Card
                card={card}
                isCreditCardClicked={isCreditCardClicked}
                handleChoose={handleChoose}
              />
            </Box>
          ))
      }
    </Grid>
  </Box>
)

export default FilterByCard
