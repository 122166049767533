
import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import Container from "@layouts/Container"
import Box from '@lib/ui/core/Box'
import Grid from '@lib/ui/core/Grid'
import ButtonBase from '@lib/ui/core/ButtonBase'
import Typography from '@lib/ui/core/Typography'
import useResponsive from '@hooks/use-responsive'
import SvgIcon from '@lib/ui/core/SvgIcon'
import Card from './Card'

const AddCard = ({ onClick }) => (
  <Box display="flex" justifyContent="center">
    <ButtonBase onClick={onClick}>
      <Box textAlign="center">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          width="3.8rem"
          height="3.8rem"
          m="1.2rem auto"
          bgcolor="background.circleBtn"
        >
          <SvgIcon component={AddIcon} fontSize="2.1rem" />
        </Box>
        <Typography>添加信用卡</Typography>
      </Box>
    </ButtonBase>
  </Box>
)

const CreditCard = ({ isLogin, creditCards, handleClick }) => {
  const r = useResponsive()

  return (
    <Container>
      <Box mt={{ xs: 2, md: 4 }}>
        {creditCards.length > 0 ? (
          <Box>
            <Box mb={{ xs: 2, md: 4 }}>
              <Grid container spacing={r({ xs: 1, md: 3 })}>
                {creditCards.length > 0 && (
                  creditCards.map((card) => (
                    <Grid item md={4} sm={6} xs={12} key={isLogin ? card.id : card.prismicId}>
                      <Card item md={4} sm={6} xs={12} card={card} />
                    </Grid>
                  )))}
              </Grid>
            </Box>
            <AddCard onClick={handleClick} />
          </Box>
        ) : (
          <Box bgcolor="background.gray" borderRadius="1rem" px={2} py={2.5} textAlign="center">
            <Typography>立刻話畀我地知你用緊邊張信用卡，我地會將最相關既優惠帶畀你！</Typography>
            <AddCard onClick={handleClick} />
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default CreditCard
