/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { ReactComponent as CreateIcon } from '@assets/icons/pencil.svg'
import { ReactComponent as LogoutIcon } from '@assets/icons/log-in.svg'
import { useStoreState, useStoreActions } from 'easy-peasy'
import SvgIcon from '@lib/ui/core/SvgIcon'
import AddIcon from '@material-ui/icons/Add'
import ButtonBase from '@lib/ui/core/ButtonBase'
import IconButton from '@lib/ui/core/IconButton'
import Link from '@lib/ui/core/Link'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import UserProfileImg from '@assets/images/profile.png'
import urljoin from 'url-join'
import { getToken, getRefreshToken, removeToken } from '@utils/auth'
import { logout } from '@services/api/auth'

const Profile = ({ isLogin, profile, interests, creditCards, handleClick }) => {
  const loginUrl = typeof window !== `undefined` ? urljoin(process.env.GATSBY_NMG_AUTH_URL, `sso?app_id=${process.env.GATSBY_NMG_AUTH_APP_ID}&redirect_url=${window.location.href}`) : null
  const cleanUserData = useStoreActions((action) => action.user.cleanUserData)
	const [isShowProfilePopup, setIsShowProfilePopup] = useState(false)
	
	const goToEditProfile = () => {
    navigate('/profile-edit')
  }
	
  const hideProfilePopup = () => {
    setIsShowProfilePopup(false)
  }

  const handleLogout = () => {
    logout({
      refresh_token: getRefreshToken(),
    }).then((res) => {
      if (res.status) {
        removeToken()
        cleanUserData()
        navigate('/')
      }
    })
  }

  const showInterest = () => {
    let items = null
    const interestItems = interests.slice(0, 2)
    if (interestItems.length !== 0) {
      items = interestItems.map((item) => item.content.uid)
      return items.length === 2 ? `${items[0]} / ${items[1]}` : `${items[0]}`
    }
    return items
  }

  const showCreditCards = () => {
    let items = []
    const creditCardItems = creditCards.slice(0, 3)
    if (creditCardItems.length !== 0) {
      items = creditCardItems.map((item) => {
        const card = {
          id: item.id,
          image: item.content.data.card_image.url,
        }
        return card
      })
    }
    return items
  }

  return (
    <Box bgcolor="background.gray">
      <Box display="flex">
        <Box pt={3.8} pb={1.2} pr={2.4}>
          <Box position="relative">
            {/* eslint-disable-next-line */}
            <a href={isLogin ? null : loginUrl} style={isLogin ? { cursor: "default" } : {}}>
              <Box width="6.2rem" height="6.2rem" display="block" borderRadius="50%" clone>
                {isLogin ? <img src={profile.avatar || UserProfileImg} alt="" /> : <img src={UserProfileImg} alt="" />}
              </Box>
            </a>
            {isLogin ? (
              <Box position="absolute" right="0" bottom="0" bgcolor="#AAAAAA" borderRadius="50%">
                <Box width="1.5rem" height="1.5rem" clone>
                  <IconButton style={{ padding: 0 }} onClick={goToEditProfile}>
                    <SvgIcon component={CreateIcon} border={1} borderColor="#fff" color="#AAAAAA" fontSize="1rem" />
                  </IconButton>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box mt={4.2}>
          <Typography variant="h2">{isLogin ? profile.nickname || '著數子' : '未登入'}</Typography>
          <Box mt={0.7}>
            {isLogin ? (
              <Typography>
                興趣: {showInterest() || ' /'}
              </Typography>
            ) : (
              <Typography style={{ fontSize: '.75rem' }} color="textSecondary">
                登入後有助日後找回您的設定
              </Typography>
            )}
          </Box>
          <Box mt={isLogin ? 1.7 : 0.7}>
            {isLogin ? (
              <Box>
                {showCreditCards().length !== 0 ? (
                  <Box display="flex">
                    {showCreditCards().map((card) => (
                      <Box key={card.id} width="2.5rem" height="1.6rem" mr=".4rem" clone>
                        <img src={card.image} alt="" />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box border="1px solid" borderColor="text.secondary" width="2.5rem" height="1.6rem" textAlign="center">
                    <ButtonBase onClick={handleClick} disableRipple>
                      <SvgIcon component={AddIcon} fontSize="1rem" color="text.secondary" />
                    </ButtonBase>
                  </Box>
                )}
              </Box>
            ) : (
              <Link href={loginUrl} underline="always">
                <Typography variant="h4">
                  登入/成為 JetsoBee 會員
                </Typography>
              </Link>
            )}
          </Box>
        </Box>
        {isLogin && (
        <Box mt={4.5} ml="auto">
          <Box display="flex">
            <SvgIcon component={LogoutIcon} fontSize="2rem" color="background.gray" mr={1} />
            <ButtonBase onClick={handleLogout} style={{ width: '100%', color: '#CEA542' }}>
              <Typography component="span" style={{ fontWeight: '700' }}>
                登出
              </Typography>
            </ButtonBase>
          </Box>
        </Box>
        )}
      </Box>
    </Box>
  )
}

export default Profile
