import React from 'react'
import { useStoreActions } from 'easy-peasy'
import { path } from 'ramda'
import { getToken } from '@utils/auth'
import { delCreditCard } from '@services/api/user'
import IconButton from '@lib/ui/core/IconButton'
import Grid from '@lib/ui/core/Grid'
import Typography from '@lib/ui/core/Typography'
import SvgIcon from '@lib/ui/core/SvgIcon'
import Box from '@lib/ui/core/Box'
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg'

const Card = ({ card }) => {
  const isLogin = !!getToken()
  const deleteCreditCard = useStoreActions((actions) => actions.user.deleteCreditCard)
  const delVisitorCards = useStoreActions((actions) => actions.visitor.deleteCreditCard)

  const handleDelCreditCard = (selectedCard) => {
    if (isLogin) {
      delCreditCard(selectedCard.id).then((res) => {
        if (res.data && res.data.status) {
          deleteCreditCard(selectedCard.id)
        }
      })
    } else {
      delVisitorCards(selectedCard.prismicId)
    }
  }
  return (
    <Box bgcolor="background.gray" borderRadius="1rem" p={1.8} clone>
      <Grid container alignItems="stretch">
        <Grid item xs={3}>
          <Box borderRadius=".3rem" clone width="100%" display="block">
            <img src={isLogin ? path(['content', 'data', 'card_image', 'url'], card) : path(['card_image', 'url'], card)} alt="" />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box pl={1.8} display="flex" alignItems="center" height="100%">
            <Box pr={1.8}>
              <Typography variant="body2">{isLogin ? path(['content', 'data', 'name'], card) : path(['name'], card)}</Typography>
            </Box>
          </Box>
        </Grid>
        <Box alignSelf="center" clone>
          <Grid item xs={1}>
            <IconButton onClick={() => handleDelCreditCard(card)} style={{ padding: '5px' }}>
              <SvgIcon component={DeleteIcon} fontSize="1.5rem" />
            </IconButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}

export default Card
