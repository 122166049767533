import React from 'react'
import Box from '@lib/ui/core/Box'
import List from '@lib/ui/core/List'
import Typography from '@lib/ui/core/Typography'
import InterestItem from './Item'

const InterestList = ({ userInterests, interests }) => {
  if (!(Array.isArray(userInterests) && Array.isArray(interests))) {
    return null
  }

  const otherInterests = interests.filter((interest) => (
    userInterests.length === 0
      ? !interest.is_featured
      : !userInterests.some((userInterest) => (
        userInterest.content.id === interest.prismicId
      ))
  ))

  const Subtitle = ({ children }) => (
    <Typography variant="caption" color="textSecondary" style={{ padding: ".2rem" }}>
      {children}
    </Typography>
  )

  return (
    <Box>
      {userInterests.length === 0 && <Subtitle>熱門</Subtitle>}
      <Box mb={1.5}>
        <List>
          {userInterests.length === 0
            ? interests
              .filter((interest) => interest.is_featured)
              .map((featured) => <InterestItem key={featured.prismicId} interest={featured} add featured />)
            : userInterests
              .map((interest) => <InterestItem key={interest.content.id} interest={interest.content} deleteId={interest.id} />)}
        </List>
      </Box>
      <Subtitle>其他興趣</Subtitle>
      <Box>
        <List>
          {otherInterests.map((interest) => <InterestItem key={interest.prismicId} interest={interest} add />)}
        </List>
      </Box>
    </Box>
  )
}

export default InterestList
