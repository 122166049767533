import React from 'react'
import Box from '@lib/ui/core/Box'
import SvgIcon from '@lib/ui/core/SvgIcon'
import { ReactComponent as TickSvgIcon } from '@assets/icons/middle-tick.svg'

const TickIcon = (props) => (
  <Box {...props} bgcolor="primary.main" borderRadius="2rem" display="flex" justifyContent="center" alignItems="center" position="absolute" top="-.2rem" right="-.3rem" width="1.9rem" height="1.9rem">
    <SvgIcon component={TickSvgIcon} fontSize="1.1rem" />
  </Box>
)

export const SmallTickIcon = (props) => (
  <Box {...props} width="1.22rem" height="1.22rem">
    <SvgIcon component={TickSvgIcon} fontSize=".7rem" />
  </Box>
)

export default TickIcon
