import React from 'react'
import Layout from '@layouts/MainLayout'
import Container from "@layouts/Container"
import Typography from '@lib/ui/core/Typography'
import Box from '@lib/ui/core/Box'
import Button from '@lib/ui/core/Button'
import Drawer from '@lib/ui/core/Drawer'
import SvgIcon from '@lib/ui/core/SvgIcon'
import { ReactComponent as AddIcon } from '@assets/icons/add.svg'
import { ReactComponent as DecreaseIcon } from '@assets/icons/decrease.svg'
import FilterByCard from './FilterByCard'
import styled from 'styled-components'

const StyledTypography = styled(Typography)`
  :hover {
    cursor: pointer;
  }
`

const CardAdd = ({ headerTitle, customHeader, onClose, showBankDrawer, selectBanks, selectedCards, toggleCreditCard, isCreditCardClicked, handleChoose, handleAddCreditCard }) => (
  <Drawer width="100%" open={showBankDrawer}>
    <Layout headerTitle={headerTitle} closeDrawer={onClose} customHeader={customHeader}>
      <Container mt={{ xs: 5, sm: 7.5 }}>
        <Box my={{ xs: 1.5, sm: 2.5 }} color="actionText.active" onClick={onClose} textAlign="right">
          <StyledTypography variant="h4">取消</StyledTypography>
        </Box>
        {selectBanks.map((item) => (
          <Box mb={1} key={item.prismicId}>
            <Box
              py={1.2}
              // height="2.4rem"
              // lineHeight="2.4rem"
              bgcolor="grey.100"
              borderRadius="2rem"
              pl={2}
              position="relative"
              onClick={() => toggleCreditCard(item.uid, item.prismicId)}
              style={{ cursor: 'pointer' }}
              item_name={item.name}
              item_type="offer_filter_card"
            >
              <Typography component="span">{item.name}</Typography>
              <Box pl={1} clone>
                <Typography component="span" color="textSecondary">{selectedCards.filter((card) => card.bankId === item.prismicId).length || null}</Typography>
              </Box>
              <Box position="absolute" top="0" right=".94rem" height="2.4rem" display="flex" alignItems="center">
                <SvgIcon component={item.isShow ? DecreaseIcon : AddIcon} fontSize="1.2rem" />
              </Box>
            </Box>
            {item.isShow ? <FilterByCard creditCards={item.subcards} isCreditCardClicked={isCreditCardClicked} handleChoose={handleChoose} /> : null}
          </Box>
        ))}
        <Box my={{ xs: 4, sm: 6.5 }} position={selectBanks.some(({ isShow }) => isShow) ? "sticky" : "block"} bottom={15}>
          <Button fullWidth onClick={handleAddCreditCard}>添加已選擇信用卡</Button>
        </Box>
      </Container>
    </Layout>
  </Drawer>
)

export default CardAdd
