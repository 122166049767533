import React, { useState, useEffect } from 'react'
import Container from "@layouts/Container"
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import Spinner from '@components/Spinner'
import { getInterests } from '@services/prismic'
import InterestList from './List'

const Interest = ({ interests, summaryLoading }) => {
  const [interestList, setInterestList] = useState([])
  const [isLoading, setLoading] = useState(false)

  const fetchInterestList = async () => {
    setLoading(true)
    const { results } = await getInterests({ options: { pageSize: 100 } })
    setInterestList(results)
    setLoading(false)
  }

  useEffect(() => {
    fetchInterestList()
  }, [])

  return (
    <Container>
      <Box maxWidth={360} m="auto" py={4}>
        <Typography variant="body1" align="center">
          系統會依據這些資訊為您提供個人化的搜尋
        </Typography>
      </Box>
      {summaryLoading || isLoading ? (
        <Box display="flex" justifyContent="center" my={5}>
          <Spinner />
        </Box>
      ) : <InterestList userInterests={interests} interests={interestList} />}
    </Container>
  )
}

export default Interest
