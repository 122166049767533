/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { navigate } from '@reach/router'
import Container from '@layouts/Container'
import Box from '@lib/ui/core/Box'
import Tabs from '@lib/ui/core/Tabs';
import Tab from '@lib/ui/core/Tab';
import Typography from '@lib/ui/core/Typography'
import Spinner from '@components/Spinner'
import CreditCard from '@components/Search/FilterByCard'
import Interest from '@components/Account/Interest'
import { getQueryString } from '@utils/url'
import { getToken } from '@utils/auth'
import { getUserSummary, addCreditCardBatch, delCreditCard } from '@services/api/user'
import { getBanks, getCreditCards } from "@services/prismic"
import CardAdd from '@components/Search/FilterByCard/CardAdd'
import Profile from './Profile'

const AccountPanel = () => {
  const isLogin = !!getToken()
  const [tab, setTab] = useState('credit_card') // default display tab
  const [showBankDrawer, setShowBankDrawer] = useState(false)
  const [selectBanks, setSelectBanks] = useState([])
  const [selectedCards, setSelectedCards] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const email = useStoreState((state) => state.user.email)
  const profile = useStoreState((state) => state.user.profile)
  const creditCards = useStoreState((state) => state.user.creditCards)
  const interests = useStoreState((state) => state.user.interests)
  const visitorCards = useStoreState((actions) => actions.visitor.creditCards)
  const updateEmail = useStoreActions((actions) => actions.user.updateEmail)
  const updateProfile = useStoreActions((actions) => actions.user.updateProfile)
  const updateCreditCards = useStoreActions((actions) => actions.user.updateCreditCards)
  const addCreditCards = useStoreActions((actions) => actions.user.addCreditCards)
  const addVisitorCards = useStoreActions((actions) => actions.visitor.addCreditCard)
  const removeAllVisitorCards = useStoreActions((actions) => actions.visitor.removeAllCreditCard)
  const deleteCreditCard = useStoreActions((actions) => actions.user.deleteCreditCard)
  const delVisitorCards = useStoreActions((actions) => actions.visitor.deleteCreditCard)
  const updateInterests = useStoreActions((actions) => actions.user.updateInterests)

  useEffect(() => {
    const currentTab = getQueryString('tab') || tab
    setTab(currentTab)
  }, [])

  useEffect(() => {
    const fetchUserSummary = async () => {
      const res = await getUserSummary()
      const { data } = res
      updateEmail(data.email)
      updateProfile(data.profile)
      updateCreditCards(data.credit_cards)
      updateInterests(data.interests)
      setIsLoading(false)
      removeAllVisitorCards()
    }
    if (isLogin) {
      if (!email) {
        setIsLoading(true)
        fetchUserSummary()
      }
    }
  }, [])

  // open credit card select page
  const handleClick = async () => {
    if (selectBanks.length === 0) {
      let { results } = await getBanks({
        options: {
          pageSize: 100,
        },
      })
      results = results.map((bank) => {
        bank.isShow = false
        bank.selected = 0
        return bank
      })
      if (isLogin) {
        setSelectedCards([...creditCards.map(({ content }) => {
          content.prismicId = content.id
          content.bankId = content.data.bank.id
          content.card_image = {}
          content.card_image.url = content.data.card_image.url
          return content
        })])
      } else {
        setSelectedCards(visitorCards)
      }
      setSelectBanks(results)
    }
    setShowBankDrawer(true)
  }

  const handleClose = () => {
    setShowBankDrawer(false)
    setSelectedCards([])
    setSelectBanks([])
  }

  const toggleCreditCard = async (uid, prismicId) => {
    const { results } = await getCreditCards({
      filter: {
        bankId: prismicId,
      },
      options: {
        pageSize: 100,
      },
    });
    setSelectBanks(selectBanks.map((item) => {
      if (item.uid === uid) {
        item.isShow = !item.isShow
        results.map((card) => {
          const newCard = {}
          card.bankId = prismicId
          return newCard
        })
        item.subcards = results
      }
      return item
    }))
  }

  const isCreditCardClicked = (prismicId) => selectedCards.find((o) => o.prismicId === prismicId)

  const handleChoose = (card) => {
    const isClicked = selectedCards.find((o) => o.prismicId === card.prismicId)
    if (isClicked) {
      // remove
      setSelectBanks(selectBanks.map((item) => {
        if (item.prismicId === card.bankId) {
          item.selected -= 1
        }
        return item
      }))
      setSelectedCards(selectedCards.filter((o) => o.prismicId !== card.prismicId))
    } else {
      setSelectBanks(selectBanks.map((item) => {
        if (item.prismicId === card.bankId) {
          item.selected += 1
        }
        return item
      }))
      setSelectedCards([...selectedCards, card])
    }
  }

  const handleAddCreditCard = () => {
    if (isLogin) {
      const choosenCards = selectedCards.map((card) => {
        const obj = {}
        obj.creditcard_id = card.prismicId
        return obj
      })
      creditCards
        .filter((card) => selectedCards.every((seleted) => seleted.prismicId !== card.content.id))
        .forEach((card) => delCreditCard(card.id).then((res) => {
          if (res.data && res.data.status) {
            deleteCreditCard(card.id)
          }
        }))
      addCreditCardBatch(choosenCards).then((res) => {
        const newCards = res.data
        if (Array.isArray(newCards)) {
          handleClose()
          addCreditCards(newCards)
        }
      })
    } else {
      handleClose()
      visitorCards
        .filter((card) => selectedCards.every((seleted) => seleted.prismicId !== card.prismicId))
        .forEach((card) => delVisitorCards(card.prismicId))
      addVisitorCards(selectedCards)
    }
  }

  const tabs = [
    {
      key: 'credit_card',
      value: '信用卡',
      count: isLogin ? creditCards.length : visitorCards.length,
      component: !isLoading ? <CreditCard creditCards={isLogin ? creditCards : visitorCards} handleClick={handleClick} isLogin={isLogin} /> : (
        <Box display="flex" justifyContent="center" my={4}>
          <Spinner />
        </Box>
      ),
    },
    {
      key: 'interest',
      value: '興趣',
      count: interests.length,
      component: <Interest interests={interests} summaryLoading={isLoading} isLogin={isLogin} setTab={setTab} />,
      hide: !isLogin,
    },
  ]

  const changeTab = (ev, value) => {
    navigate(`?tab=${value}`)
    setTab(value)
  }

  return (
    <>
      <Box>
        <Box bgcolor="background.gray" border={1} borderTop={0} borderColor="grey.A100">
          <Container px="1.9rem">
            <Profile profile={profile} interests={interests} creditCards={creditCards} isLogin={isLogin} handleClick={handleClick} />
            <Tabs
              value={tab}
              onChange={changeTab}
            >
              {tabs.filter(({ hide }) => !hide).map(({ key, value, count }) => (
                <Tab
                  key={key}
                  value={key}
                  p={0}
                  disableRipple
                  label={<Typography variant="subtitle1">{value} {count}</Typography>}
                  style={{ minWidth: 50, marginRight: 20, opacity: 1 }}
                />
              ))}
            </Tabs>
          </Container>
        </Box>
        {tabs.map((tabItem) => {
          if (tab === tabItem.key) {
            if (tab === "interest" && !isLogin) {
              navigate("/account/?tab=credit_card")
              const currentTab = getQueryString('tab')
              setTab(currentTab)
            } else {
              return <Box key={tabItem.key} pb={20}>{tabItem.component}</Box>
            }
          }
          return null
        })}
      </Box>
      <CardAdd
        headerTitle="加入其他信用卡"
        showBankDrawer={showBankDrawer}
        selectBanks={selectBanks}
        selectedCards={selectedCards}
        onClose={handleClose}
        toggleCreditCard={toggleCreditCard}
        isCreditCardClicked={isCreditCardClicked}
        handleChoose={handleChoose}
        handleAddCreditCard={handleAddCreditCard}
      />
    </>
  )
}
export default AccountPanel
